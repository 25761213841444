import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";

import InstructionsImage from "./instructions-cover.png";
import CircledBackButton from "../../../../../Common/components/CircledBackButton";

interface ScanInstructionsProps {
  onConfirm: () => void;
  onBackButtonClick: () => void;
  instructionsSet: {
    set1: string[];
    set2: string[];
  };
}

interface ListWrapperProps {
  headingText: string;
  instructions: string[];
  icon: any;
}

function ListWrapper({ instructions, headingText, icon }: ListWrapperProps) {
  return (
    <>
      <Typography variant="h2">{headingText}</Typography>
      <Stack
        component="ul"
        role="list"
        aria-label={`${headingText} instruction list`}
      >
        {instructions.map((listItemText) => (
          <ListItem
            role="listitem"
            sx={{ padding: "2px 0" }}
            key={listItemText}
          >
            <ListItemIcon sx={{ display: "contents" }}>{icon}</ListItemIcon>
            <ListItemText sx={{ pl: 2 }}>{listItemText}</ListItemText>
          </ListItem>
        ))}
      </Stack>
    </>
  );
}

export default function ScanInstructions({
  onConfirm,
  onBackButtonClick,
  instructionsSet,
}: ScanInstructionsProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        p={2}
        spacing={2}
        sx={{
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h2" fontWeight={200}>
            {t("BinahScanFlow.instructions.title")}
          </Typography>
          <Typography variant="body2">
            {t("BinahScanFlow.instructions.subtitle")}
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundImage: `url(${InstructionsImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            minHeight: "203px",
            mb: 2,
          }}
        />
        <Stack spacing={3} sx={{ flex: 2 }}>
          <Box>
            <ListWrapper
              headingText={t("BinahScanFlow.instructions.set1Title")}
              icon={
                <WatermarkIcon
                  height={22}
                  width={23}
                  color="primary.900"
                  foreground="info"
                  Icon={"CheckmarkIcon"}
                />
              }
              instructions={instructionsSet.set1}
            />
          </Box>
          <Box>
            <ListWrapper
              headingText={t("BinahScanFlow.instructions.set2Title")}
              icon={
                <WatermarkIcon
                  height={22}
                  width={23}
                  color="neutral.900"
                  foreground="info"
                  Icon={"CrossIcon"}
                />
              }
              instructions={instructionsSet.set2}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button fullWidth onClick={onConfirm}>
            {t("common.continueButton")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
