import { Button, Stack } from "@mui/material";
import { createElement, useState } from "react";
import { trackEvent } from "../../../../../../services/analytics-adapter";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../../../../../theme/icons/FaceIconBrandLarge";
import QuestionTypeSexAtBirth from "../../../../../Common/components/QuestionTypeSexAtBirth";
import QuestionTypeWeight from "../../../../../Common/components/QuestionTypeWeight";
import QuestionTypeDateOfBirth from "../../../../../Common/components/QuestionTypeDateOfBirth";
import { Gender } from "../../../../types";
import CollectUserPersonalInformation from "../CollectUserPersonalInformation";

interface PreFaceScanFlowProps {
  onComplete: Function;
  onHandlerNextQuestionType: Function;
}

type QuestionTypeName =
  | "PersonalInformation"
  | "SexAtBirth"
  | "Weight"
  | "DateOfBirth";

interface QuestionTypesMetadata {
  [key: string]: {
    questionType: QuestionTypeName;
    component: any;
    onChange?: (value: any) => void;
    onCompletedFlow?: () => void;
  };
}

export default function PreFaceScanFlow({
  onComplete,
  onHandlerNextQuestionType,
}: PreFaceScanFlowProps) {
  const stateNames: { [key: string]: QuestionTypeName } = {
    QUESTION_TYPE_PERSONAL_INFORMATION: "PersonalInformation",
    QUESTION_TYPE_SEX_AT_BIRTH: "SexAtBirth",
    QUESTION_TYPE_WEIGHT: "Weight",
    QUESTION_TYPE_DATE_OF_BIRTH: "DateOfBirth",
  };

  const { t } = useTranslation();

  const [currentQuestionType, setCurrentQuestionType] =
    useState<QuestionTypeName>(stateNames.QUESTION_TYPE_PERSONAL_INFORMATION);

  const [isValidInput, setIsValidInput] = useState(false);
  const [subjectDemographic, setSubjectDemographic] = useState<any>({});

  const questionTypesMapping: QuestionTypesMetadata = {
    PersonalInformation: {
      questionType: "PersonalInformation",
      component: CollectUserPersonalInformation,
      onChange: onPersonalInformationChange,
    },
    SexAtBirth: {
      questionType: "SexAtBirth",
      component: QuestionTypeSexAtBirth,
      onChange: onSexAtBirthChange,
    },
    Weight: {
      questionType: "Weight",
      component: QuestionTypeWeight,
      onChange: onWeightChange,
    },
    DateOfBirth: {
      questionType: "DateOfBirth",
      component: QuestionTypeDateOfBirth,
      onChange: onDateOfBirthChange,
    },
  };

  function getQuestionTypeComponent() {
    const { component, onChange, onCompletedFlow } =
      questionTypesMapping[currentQuestionType];

    return createElement(component, {
      onChange: onChange,
      onCompletedFlow: onCompletedFlow,
    });
  }

  function onPersonalInformationChange(value: any) {
    setIsValidInput(value.isValid);

    if (value.isValid) {
      setSubjectDemographic({
        ...subjectDemographic,
        personalInformation: value.data,
      });

      setIsValidInput(value.isValid);
    }
  }

  function onSexAtBirthChange(value: any) {
    let gender = Gender.UNSPECIFIED;

    if (value.data.value) {
      gender = value.data.value;
      setSubjectDemographic({
        ...subjectDemographic,
        sexAtBirth: gender,
      });
      setIsValidInput(true);
    }
  }

  function onDateOfBirthChange(value: any) {
    if (value.isValid) {
      setSubjectDemographic({
        ...subjectDemographic,
        dateOfBirth: value.data.value,
      });
      setIsValidInput(true);
    }
  }

  function onWeightChange(value: any) {
    if (value.isValid) {
      setSubjectDemographic({
        ...subjectDemographic,
        weight: value.data.value,
      });
      setIsValidInput(true);
    }
  }

  const onContinue = () => {
    setIsValidInput(false);
    onHandlerNextQuestionType();

    trackEvent({
      event: "action.facePreScanContinue",
      source: "Public binah scan flow",
      questionType: currentQuestionType,
    });

    if (currentQuestionType === stateNames.QUESTION_TYPE_PERSONAL_INFORMATION) {
      setCurrentQuestionType(stateNames.QUESTION_TYPE_SEX_AT_BIRTH);
    }

    if (currentQuestionType === stateNames.QUESTION_TYPE_SEX_AT_BIRTH) {
      setCurrentQuestionType(stateNames.QUESTION_TYPE_WEIGHT);
    }

    if (currentQuestionType === stateNames.QUESTION_TYPE_WEIGHT) {
      setCurrentQuestionType(stateNames.QUESTION_TYPE_DATE_OF_BIRTH);
    }

    if (currentQuestionType === stateNames.QUESTION_TYPE_DATE_OF_BIRTH) {
      onComplete(subjectDemographic);
    }
  };

  return (
    <Stack spacing={2} p={2} justifyContent="flex-end" sx={{ height: "100%" }}>
      <FaceIconBrandLarge />

      {getQuestionTypeComponent()}

      <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
        <Button fullWidth disabled={!isValidInput} onClick={onContinue}>
          {t("common.nextButton")}
        </Button>
      </Stack>
    </Stack>
  );
}
