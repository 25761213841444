import { Suspense, lazy, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Prompt from "./components/Prompt";
import PreFaceScanFlow from "./components/PreScanFlow";
import ScanInstructions from "./components/ScanInstructions";
import { useTranslation } from "react-i18next";
import FlowHeader from "../../../Common/components/FlowHeader";
import { Stack } from "@mui/material";
import FullscreenLoadingIndicator from "../../../Common/components/FullscreenLoadingIndicator";
import { detect } from "detect-browser";
import UnsupportedDeviceError from "./components/UnsupportedBrowserError";
import GenericError from "./components/GenericError";
import { useSearchParams } from "react-router-dom";
import { HealthCheckQuestionnaireData } from "../../../../services/core-api-adapter";

const BinahMeasure = lazy(() => import("./components/Measure"));

const initialiseMonitor = () => {
  async function dynamicallyLoadMonitor() {
    const monitor = (await import("@binah/web-sdk")).default;
    return monitor;
  }

  dynamicallyLoadMonitor().then((monitor) => {
    if (window.Cypress) {
      window.binahScanMonitor = monitor;
    }
  });
};

initialiseMonitor();

enum ErrorMap {
  UNSUPPORTED_BROWSER_IOS = "UNSUPPORTED_BROWSER_IOS",
  UNSUPPORTED_BROWSER_ANDROID = "UNSUPPORTED_BROWSER_ANDROID",
}

interface DeviceCompatibilityErrorProps {
  errorType: any;
  onComeBackLater: Function;
  onTryAgain?: Function;
}

function DeviceCompatibilityError({
  errorType,
  onComeBackLater,
  onTryAgain,
}: DeviceCompatibilityErrorProps) {
  const navigate = useNavigate();

  switch (errorType) {
    case ErrorMap.UNSUPPORTED_BROWSER_IOS:
      return (
        <UnsupportedDeviceError
          onComeBackLater={onComeBackLater}
          deviceOS="IOS"
        />
      );
    case ErrorMap.UNSUPPORTED_BROWSER_ANDROID:
      return (
        <UnsupportedDeviceError
          onComeBackLater={onComeBackLater}
          deviceOS="ANDROID_OS"
        />
      );
  }

  const onGenericErrorTryAgain = () => {
    if (onTryAgain) {
      return onTryAgain;
    } else {
      return navigate("/home");
    }
  };

  return (
    <GenericError
      onComeBackLater={onComeBackLater}
      onTryAgain={onGenericErrorTryAgain}
    />
  );
}

interface PublicBinahScanFlowProps {
  onCompletedFlow?: Function;
}

enum BinahScanFlowStates {
  LOADING_SCAN_PROMPT = "LOADING_SCAN_PROMPT",
  SCAN_PROMPT = "SCAN_PROMPT",
  PRE_SCAN_QUESTIONNAIRE = "PRE_SCAN_QUESTIONNAIRE",
  PRE_SCAN_INSTRUCTIONS = "PRE_SCAN_INSTRUCTIONS",
  START_SCAN = "START_SCAN",
}

export default function PublicBinahScanFlow({
  onCompletedFlow,
}: PublicBinahScanFlowProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [deviceCompatibilityError, setDeviceCompatibilityError] =
    useState<ErrorMap | null>(null);
  const [flowHeaderCounter, setFlowHeaderCounter] = useState<number>(1);

  const navigationState = location.state as {
    startScanConfirmed: boolean;
    isPreScanComplete: boolean;
  };
  const [binahScanFlowState, setBinahScanFlowState] =
    useState<BinahScanFlowStates>(BinahScanFlowStates.LOADING_SCAN_PROMPT);

  const [healthCheckQuestionnaireData, setHealthCheckQuestionnaireData] =
    useState<HealthCheckQuestionnaireData | null>(null);

  const instructionsSet1 =
    t("BinahScanFlow.instructions.set1", {
      returnObjects: true,
    }) || [];

  const instructionsSet2 =
    t("BinahScanFlow.instructions.set2", {
      returnObjects: true,
    }) || [];

  function onPromptAccept() {
    setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_QUESTIONNAIRE);
  }

  function onPreScanComplete(subjectDemographic: any) {
    setHealthCheckQuestionnaireData(subjectDemographic);
    setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_INSTRUCTIONS);
    setFlowHeaderCounter(1);
  }

  const skipScanPromptQueryParam = "skipScanPrompt";

  useEffect(() => {
    if (location.state?.showInstructionsScreen) {
      setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_INSTRUCTIONS);
    } else if (navigationState?.startScanConfirmed) {
      setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_QUESTIONNAIRE);
    }
  }, []);

  useEffect(() => {
    const device = detect();
    const deviceBrowser = device?.name || "unknown";
    const deviceOsNormalised =
      device?.os?.toUpperCase().split(" ").join("_") || "";

    const isIOSWithNonSafariBrowser =
      deviceOsNormalised === "IOS" && deviceBrowser !== "ios";
    const isAndroidWithNonChromeBrowser =
      deviceOsNormalised === "ANDROID_OS" && deviceBrowser !== "chrome";

    const incompatibleDeviceOsAndBrowser =
      isAndroidWithNonChromeBrowser || isIOSWithNonSafariBrowser;

    if (isIOSWithNonSafariBrowser) {
      setDeviceCompatibilityError(ErrorMap.UNSUPPORTED_BROWSER_IOS);
    }

    if (isAndroidWithNonChromeBrowser) {
      setDeviceCompatibilityError(ErrorMap.UNSUPPORTED_BROWSER_ANDROID);
    }

    if (incompatibleDeviceOsAndBrowser) {
      onDecline();
    }

    if (!incompatibleDeviceOsAndBrowser) {
      if (searchParams.get(skipScanPromptQueryParam)) {
        setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_QUESTIONNAIRE);
      } else {
        setBinahScanFlowState(BinahScanFlowStates.SCAN_PROMPT);
      }
    }
  }, []);

  function onCompletedScan() {
    setBinahScanFlowState(BinahScanFlowStates.SCAN_PROMPT);
  }

  function onScanRetry() {
    setBinahScanFlowState(BinahScanFlowStates.SCAN_PROMPT);
  }

  function onConfirmScanInstructions() {
    setBinahScanFlowState(BinahScanFlowStates.START_SCAN);
  }

  function onDecline() {
    onCompletedScan();
  }

  function onBackButtonClick() {
    setBinahScanFlowState(BinahScanFlowStates.SCAN_PROMPT);
  }

  function handleScanFlowHeaderCounter() {
    setFlowHeaderCounter((prevState) => (prevState += 1));
  }
  if (deviceCompatibilityError) {
    return (
      <DeviceCompatibilityError
        onComeBackLater={onDecline}
        errorType={deviceCompatibilityError}
      />
    );
  }

  return (
    <>
      <Stack sx={{ height: "100%" }}>
        {binahScanFlowState === BinahScanFlowStates.LOADING_SCAN_PROMPT && (
          <FullscreenLoadingIndicator mainIconType="face" />
        )}
        {binahScanFlowState !== BinahScanFlowStates.START_SCAN &&
          binahScanFlowState !== BinahScanFlowStates.PRE_SCAN_INSTRUCTIONS && (
            <FlowHeader
              title={t("MemberRequiredActionsFlow.BINAH_SCAN.title")}
              value={flowHeaderCounter}
              max={4}
              isBackButtonVisible={false}
            />
          )}

        {binahScanFlowState === BinahScanFlowStates.SCAN_PROMPT && (
          <Prompt onAccept={onPromptAccept} onDecline={onDecline} />
        )}

        {binahScanFlowState === BinahScanFlowStates.PRE_SCAN_QUESTIONNAIRE && (
          <PreFaceScanFlow
            onHandlerNextQuestionType={handleScanFlowHeaderCounter}
            onComplete={onPreScanComplete}
          />
        )}

        {binahScanFlowState === BinahScanFlowStates.PRE_SCAN_INSTRUCTIONS && (
          <ScanInstructions
            instructionsSet={{
              set1: [...instructionsSet1],
              set2: [...instructionsSet2],
            }}
            onConfirm={onConfirmScanInstructions}
            onBackButtonClick={onBackButtonClick}
          />
        )}

        {binahScanFlowState === BinahScanFlowStates.START_SCAN && (
          <Suspense
            fallback={<FullscreenLoadingIndicator mainIconType="face" />}
          >
            <BinahMeasure
              onCompletedScan={onCompletedScan}
              onScanRetry={onScanRetry}
              healthCheckQuestionnaireData={healthCheckQuestionnaireData}
              onBackButtonClick={() =>
                setBinahScanFlowState(BinahScanFlowStates.PRE_SCAN_INSTRUCTIONS)
              }
            />
          </Suspense>
        )}
      </Stack>
    </>
  );
}
