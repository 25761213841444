import { Trans, useTranslation } from "react-i18next";
import DefaultError from "../../../../../Common/components/DefaultError";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";
import InformationBox from "../../../../../Common/components/InformationBox";

interface GenericErrorProps {
  onComeBackLater: Function;
  onTryAgain: Function;
}

export default function GenericError({
  onComeBackLater,
  onTryAgain,
}: GenericErrorProps) {
  const { t } = useTranslation();

  return (
    <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
      <Typography variant="h2">{t("GenericError.title")}</Typography>
      <Typography variant="h3">
        {t("PublicBinahScanFlow.GenericError.subtitle")}
      </Typography>

      <Stack component="ul" role="list">
        {(
          t<any, any, string[]>(
            "PublicBinahScanFlow.GenericError.instructions",
            {
              returnObjects: true,
            }
          ) as []
        ).map((listItemText: string) => (
          <ListItem
            role="listitem"
            sx={{ padding: "2px 0" }}
            key={listItemText}
          >
            <ListItemIcon sx={{ display: "contents" }}>
              <WatermarkIcon
                height={22}
                width={23}
                color="primary.900"
                foreground="info"
                Icon={"CheckmarkIcon"}
              />
            </ListItemIcon>
            <ListItemText sx={{ pl: 2 }}>{listItemText}</ListItemText>
          </ListItem>
        ))}
      </Stack>

      <Stack spacing={2}>
        <InformationBox Icon={"PadlockIcon"}>
          <Stack spacing={2}>
            <Typography variant="body2" fontWeight="600">
              {t("PublicBinahScanFlow.GenericError.supportTip.title")}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="PublicBinahScanFlow.GenericError.supportTip.body"></Trans>
            </Typography>
          </Stack>
        </InformationBox>
      </Stack>
    </DefaultError>
  );
}
