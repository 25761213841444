import { Button, Stack, Typography } from "@mui/material";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";
import theme from "../../../../../../theme";
import FullscreenBackground from "../../../../../Common/components/FullscreenBackground";
import { useTranslation } from "react-i18next";

interface FaceScanCompleteProps {
  onContinue: Function;
  scanResults: any;
}

export default function FaceScanComplete({
  onContinue,
  scanResults,
}: FaceScanCompleteProps) {
  const { t } = useTranslation();

  function onContinueButtonClick() {
    onContinue();
  }

  if (scanResults === null) {
    return <Typography>{t("PublicBinahScanFlow.noResultsTitle")}</Typography>;
  }

  Object.entries(scanResults).map(([key, result]) => {
    const specialLookupKey = "bloodPressure";
    const extractedResult: any = result;
    if (key === specialLookupKey && extractedResult?.value.systolic) {
      scanResults[specialLookupKey] = {
        value: `${extractedResult?.value?.systolic}/${extractedResult?.value?.diastolic}`,
      };
    }
  });

  const scanResultsArray = Object.entries(
    scanResults as { [key: string]: any }
  );

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      sx={{
        minHeight: "100%",
      }}
    >
      <WatermarkIcon
        extra={{
          alignSelf: "center",
        }}
        height={128}
        width={128}
        iconSize="medium"
        color={theme.palette.primary[300]}
        foreground={theme.palette.neutral.contrastText}
        Icon={"FaceScanBlue"}
      />
      <Stack textAlign="center" py={5}>
        <Typography variant="h1" color="inherit">
          {t("PublicBinahFaceScanSuccess.title")}
        </Typography>
        {(
          t<any, any, string[]>("PublicBinahFaceScanSuccess.body", {
            returnObjects: true,
          }) as []
        ).map((text: string, index: number) => (
          <Typography
            key={String(text + index)}
            variant="h3"
            color="inherit"
            p={2}
          >
            {text}
          </Typography>
        ))}
      </Stack>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <tbody>
          {scanResultsArray.map(([key, result]) => (
            <tr
              style={{
                border: `1px solid ${theme.palette.neutral[50]}`,
                textAlign: "left",
              }}
              key={key}
            >
              {result.value && (
                <>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.neutral[50]}`,
                      textAlign: "left",
                      padding: 4,
                      textTransform: "capitalize",
                    }}
                  >
                    {key}
                  </td>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.neutral[50]}`,
                      textAlign: "left",
                      padding: 4,
                    }}
                  >
                    {result.value}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <Button sx={{ mt: 2 }} color="info" onClick={onContinueButtonClick}>
        {t("common.continueButton")}
      </Button>
      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
