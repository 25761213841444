import { HealthMonitorCodes, VitalSigns } from "@binah/web-sdk";
import {
  Box,
  Button,
  Fade,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { MemoizedScanner } from "../Scanner";
import { useCameras, useTimer } from "../../../../hooks";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import GenericError from "../GenericError";
import DeviceRequirementsError from "../DeviceRequirementsError";
import PermissionIssuesError from "../PermissionIssuesError";
import NoCameraFoundError from "../NoCameraFoundError";
import {
  HealthCheckQuestionnaireData,
  submitPublicFaceScanData,
} from "../../../../../../services/core-api-adapter";
import { InfoData } from "../../../../types";
import IconLoader from "../../../../../Common/components/IconLoader";
import FaceScanComplete from "../FaceScanComplete";
import { trackEvent } from "../../../../../../services/analytics-adapter";

enum ErrorMap {
  GENERIC = "GENERIC",
  DEVICE = "DEVICE",
  PERMISSIONS_ISSUE = "PERMISSIONS_ISSUE",
  NO_CAMERA_FOUND = "NO_CAMERA_FOUND",
  BATTERY_TOO_LOW = "BATTERY_TOO_LOW",
}

const stateNames = {
  READY_TO_SCAN: "READY_TO_SCAN",
  INITIALISE_SCANNER: "INITIALISE_SCANNER",
  INITIALISE_SCANNER_FAILED: "INITIALISE_SCANNER_FAILED",
  INITIALISE_SCANNER_SUCCEEDED: "INITIALISE_SCANNER_SUCCEEDED",
  SCAN_IN_PROGRESS: "SCAN_IN_PROGRESS",
  SCAN_COMPLETED: "SCAN_COMPLETED",
  SCAN_RETRY: "SCAN_RETRY",
  EXIT: "EXIT",
};

const DEFAULT_MEASUREMENT_DURATION =
  Number(import.meta.env.VITE_APP_BINAH_AI_SDK_SCAN_MEASUREMENT_DURATION) ||
  120;

interface FaceScanErrorProps {
  errorType: any;
  onComeBackLater: Function;
  onTryAgain: Function;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; showLabel: boolean }
) {
  const PROGRESS_SIZE = "23rem";

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "white",
        }}
        size={PROGRESS_SIZE}
        thickness={1}
        value={100}
      />

      <CircularProgress
        variant={props.variant || "determinate"}
        disableShrink
        sx={{
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        value={props.value}
        color={props.color}
        size={PROGRESS_SIZE}
        thickness={1.2}
      />
      {props.showLabel && (
        <Box
          sx={{
            top: 0,
            left: "50%",
            transform: "translate(-50%, -40%)",
            borderRadius: 5,
            width: (theme) => theme.spacing(8),
            background: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            py: 1,
            position: "absolute",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: () =>
                props.color === "success" ? "success.main" : "primary.main",
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

function FaceScanError({
  errorType,
  onComeBackLater,
  onTryAgain,
}: FaceScanErrorProps) {
  switch (errorType) {
    case ErrorMap.GENERIC:
      return (
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
    case ErrorMap.PERMISSIONS_ISSUE:
      return (
        <PermissionIssuesError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
    case ErrorMap.NO_CAMERA_FOUND:
      return (
        <NoCameraFoundError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
    case ErrorMap.DEVICE:
      return (
        <DeviceRequirementsError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
  }

  return (
    <GenericError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain} />
  );
}

interface MeasureProps {
  healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  onCompletedScan: Function;
  onScanRetry: Function;
  onBackButtonClick: Function;
}

export default function Measure({
  onCompletedScan,
  onScanRetry,
  healthCheckQuestionnaireData,
  onBackButtonClick,
}: MeasureProps) {
  const { t } = useTranslation();
  const cameras = useCameras();
  const [cameraId, setCameraId] = useState<string>("");
  const [measurementProgress, setMeasurementProgress] = useState(0);
  const [scanResults, setScanResults] = useState<VitalSigns | null>(null);
  const navigate = useNavigate();
  const measurementDuration = DEFAULT_MEASUREMENT_DURATION;
  const [error, setError] = useState({ showError: false, errorType: "" });
  const [currentState, setCurrentState] = useState(stateNames.READY_TO_SCAN);
  const [alertMessage, setAlertMessage] = useState("");
  const [startMeasuringState, setStartMeasuringState] = useState(false);
  const [isScanReady, setIsScanReady] = useState(false);
  const seconds = useTimer(startMeasuringState, measurementDuration);

  function getPercentage() {
    const computedPercentage = seconds / DEFAULT_MEASUREMENT_DURATION;
    const percentage = Math.floor(computedPercentage * 100);
    return percentage;
  }

  useEffect(() => {
    if (cameras?.length) {
      setCameraId(cameras[0].deviceId);
    }
  }, [cameras]);

  const onError = useCallback((error: any) => {
    if (error.code && error.code >= 0) {
      console.error(error);
      const errorCode = error.code;

      trackEvent({
        event: "action.faceScanError",
        source: "Public binah scan flow",
        errorCode: errorCode,
      });

      switch (errorCode) {
        case HealthMonitorCodes.DEVICE_CODE_MINIMUM_BROWSER_VERSION_ERROR:
          setError({ showError: true, errorType: ErrorMap.DEVICE });
          break;
        case HealthMonitorCodes.CAMERA_CODE_CAMERA_MISSING_PERMISSIONS_ERROR:
          setError({ showError: true, errorType: ErrorMap.PERMISSIONS_ISSUE });
          break;
        case HealthMonitorCodes.CAMERA_CODE_NO_CAMERA_ERROR:
          setError({ showError: true, errorType: ErrorMap.NO_CAMERA_FOUND });
          break;
        case HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_ERROR:
          setError({ showError: false, errorType: ErrorMap.GENERIC });
          setStartMeasuringState(false);
          setCurrentState(stateNames.SCAN_RETRY);
          break;
        default:
          setError({ showError: true, errorType: ErrorMap.GENERIC });
          break;
      }
    }
  }, []);

  function onTryAgain() {
    trackEvent({
      event: "action.faceScanRetry",
      source: "Public binah scan flow",
    });
    initiateScan();
  }

  function onStartMeasuring() {
    trackEvent({
      event: "action.faceScanStarted",
      source: "Public binah scan flow",
    });
    initiateScan();
  }

  function initiateScan() {
    setCurrentState(stateNames.INITIALISE_SCANNER);
    setStartMeasuringState(true);

    if (currentState !== stateNames.INITIALISE_SCANNER_FAILED) {
      setCurrentState(stateNames.INITIALISE_SCANNER_SUCCEEDED);
      setCurrentState(stateNames.SCAN_IN_PROGRESS);
    }
  }

  function stopMeasuring() {
    setCurrentState(stateNames.READY_TO_SCAN);
    setStartMeasuringState(false);
    setMeasurementProgress(0);
    navigate("/home");
  }

  function onScanReady() {
    setIsScanReady(true);
  }

  function onScanComplete(vitalSigns: VitalSigns) {
    trackEvent({
      event: "action.faceScanComplete",
      source: "Public binah scan flow",
    });
    setCurrentState(stateNames.SCAN_COMPLETED);
    setScanResults(vitalSigns);
    submitPublicFaceScanData({
      vitalSigns: vitalSigns,
      healthCheckQuestionnaireData: healthCheckQuestionnaireData,
    });
  }

  const onAlert = useCallback(
    (warningData: InfoData) => {
      if (warningData.type <= 0 && !error.showError) {
        setAlertMessage("");
      }

      if (warningData.message) {
        setAlertMessage(warningData.message);
      }
    },
    [error]
  );

  function onInformationIconButtonClick() {
    onBackButtonClick();
  }

  function onCameraIdChange(el: any) {
    setCameraId(el.target.value);
  }

  useEffect(() => {
    if (getPercentage() <= 100) {
      setMeasurementProgress(getPercentage());
    }
  }, [seconds]);

  return (
    <>
      {error.showError === true ? (
        <FaceScanError
          errorType={error.errorType}
          onComeBackLater={onCompletedScan}
          onTryAgain={onScanRetry}
        />
      ) : (
        <>
          <>
            {(currentState === stateNames.READY_TO_SCAN ||
              currentState === stateNames.SCAN_IN_PROGRESS ||
              currentState === stateNames.SCAN_RETRY) &&
              currentState !== stateNames.SCAN_COMPLETED && (
                <Stack
                  sx={{
                    position: "absolute",
                    zIndex: 3,
                    top: "20%",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                  spacing={2}
                >
                  <CircularProgressWithLabel
                    value={measurementProgress}
                    showLabel={currentState === stateNames.SCAN_IN_PROGRESS}
                    variant="determinate"
                    color={measurementProgress === 100 ? "success" : "primary"}
                  />
                  <Stack color="neutral.contrastText">
                    {currentState === stateNames.SCAN_RETRY ? (
                      <Typography
                        variant="h2"
                        textAlign="center"
                        color="inherit"
                      >
                        {t("common.genericErrorRetryMessage")}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          textAlign="center"
                          color="inherit"
                        >
                          {alertMessage.match("Warning")
                            ? t(
                                "BinahScanFlow.measurenow.state.SCAN_IN_PROGRESS.title"
                              )
                            : alertMessage}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              )}
            {currentState !== stateNames.SCAN_COMPLETED && (
              <Stack
                sx={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <MemoizedScanner
                  onError={onError}
                  cameraId={cameraId}
                  onAlert={onAlert}
                  onStartMeasuring={startMeasuringState}
                  measurementDuration={measurementDuration}
                  onScanReady={onScanReady}
                  onScanComplete={onScanComplete}
                  healthCheckQuestionnaireData={healthCheckQuestionnaireData}
                  onBackButton={onBackButtonClick}
                />

                <Stack
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                  }}
                >
                  <IconLoader
                    icon="InformationSymbolFilledCircleIcon"
                    onClick={onInformationIconButtonClick}
                    sx={{ fontSize: 30 }}
                  />
                </Stack>

                <Stack
                  bgcolor="background.paper"
                  sx={{
                    borderRadius: (theme: Theme) =>
                      `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
                    bottom: "0",
                    width: "100%",
                    position: "absolute",
                    px: 2,
                    py: 1.5,
                  }}
                >
                  {(currentState === stateNames.SCAN_IN_PROGRESS ||
                    currentState === stateNames.SCAN_RETRY) && (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={stopMeasuring}
                      >
                        {t("common.comeBackButton")}
                      </Button>
                      <Button
                        disabled={currentState === stateNames.SCAN_IN_PROGRESS}
                        fullWidth
                        onClick={onTryAgain}
                      >
                        {t("common.tryAgain")}
                      </Button>
                    </Stack>
                  )}

                  {currentState !== stateNames.SCAN_IN_PROGRESS &&
                    currentState === stateNames.READY_TO_SCAN && (
                      <Stack spacing={2} p={2}>
                        {cameras.length > 0 && (
                          <Stack spacing={1}>
                            <InputLabel>
                              <Typography
                                color="neutral.700"
                                variant="body2"
                                fontWeight={300}
                              >
                                {t("PublicBinahFaceScan.selectCamera.label")}
                              </Typography>
                            </InputLabel>
                            <Select
                              placeholder={t(
                                "PublicBinahFaceScan.selectCamera.label"
                              )}
                              value={cameraId}
                              onChange={onCameraIdChange}
                            >
                              {cameras.map((camera) => {
                                return (
                                  <MenuItem
                                    key={camera.deviceId}
                                    value={camera.deviceId}
                                  >
                                    {camera.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Stack>
                        )}
                        <Button
                          fullWidth
                          onClick={onStartMeasuring}
                          disabled={!isScanReady}
                        >
                          {t("BinahScanFlow.measurenow.measureButton")}
                        </Button>
                      </Stack>
                    )}
                </Stack>
              </Stack>
            )}
          </>
          {currentState !== stateNames.SCAN_IN_PROGRESS &&
            currentState === stateNames.SCAN_COMPLETED && (
              <Fade in={true}>
                <Box
                  sx={{
                    height: "100%",
                    p: 2,
                  }}
                >
                  <FaceScanComplete
                    scanResults={scanResults}
                    onContinue={onCompletedScan}
                  />
                </Box>
              </Fade>
            )}
        </>
      )}
    </>
  );
}
