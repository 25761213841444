export function execute(value: number | undefined): boolean {
  const POLICY_NUMBER_FIRST_NUM = 5;
  const stringifiedValue = String(value);

  if (!value) return false;
  return (
    Number.isInteger(Number(value)) === true &&
    stringifiedValue.length === 11 &&
    stringifiedValue.charAt(0) === String(POLICY_NUMBER_FIRST_NUM)
  );
}

export function isValid(value: number | undefined): boolean {
  return execute(value) === true;
}
