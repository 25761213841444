export enum AppErrorCode {
  MEASUREMENT_CODE_FACE_UNDETECTED_ERROR = 80001,
  MONITOR_FAILED_TO_INITIALIZE = 7005,
}

export enum InfoType {
  NONE,
  INSTRUCTION,
  SUCCESS,
}

export interface InfoData {
  type: InfoType;
  message?: string;
}

export enum VideoReadyState {
  HAVE_ENOUGH_DATA = 4,
}

export enum Gender {
  MALE = 2,
  FEMALE = 1,
  UNSPECIFIED = 0,
}

export enum DemographicLimits {
  MIN_ALLOWED_AGE = 18,
  MAX_ALLOWED_AGE = 110,
  MIN_ALLOWED_WEIGHT = 40,
  MAX_ALLOWED_WEIGHT = 200,
}
